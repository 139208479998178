import { ChevronLeft, ChevronRight, ChevronType } from './Chevron';

export const SolidBlueLeft = ({ width = 20, height = 20 }: ChevronType) => {
  return (
    <span
      data-testid="SolidBlueLeft"
      className="[ bg-blue-85/40 ][ rounded-full ][ text-blue-50 ][ flex justify-center items-center ][ p-2.5 ][ -left-17 ]"
    >
      <ChevronLeft width={width} height={height} />
    </span>
  );
};

export const SolidBlueRight = ({ width = 20, height = 20 }: ChevronType) => {
  return (
    <span
      data-testid="SolidBlueRight"
      className="[ bg-blue-85/40 ][ rounded-full ][ text-blue-50 ][ flex justify-center items-center ][ p-2.5 ][ -right-17 ]"
    >
      <ChevronRight width={width} height={height} />
    </span>
  );
};
