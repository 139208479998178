import clsx from 'clsx';

export type SolidArrowType = {
  disabeld?: boolean;
  invert?: boolean;
  circle?: boolean;
  className?: string;
  containerClassName?: string;
  scrollBarWidth?: number;
};

export function SolidArrowLeft({
  disabeld,
  invert,
  circle,
  className,
  containerClassName,
}: SolidArrowType) {
  return (
    <div
      className={clsx(
        'mobile-hidden',
        '[ rounded-r-4xl ][ flex items-center ][ p-2.5 ][ absolute left-0 top-9/20 ]',
        {
          '[ pointer-events-none ]': disabeld,
          '[ bg-white ]': !invert && !circle,
          '[ bg-gray-30 ]': invert && !circle,
          '[ bg-blue-85/40 ][ rounded-full ][ justify-center ][ w-10 h-10 ]': circle,
        },
        containerClassName,
      )}
      data-testid="SolidArrowLeft"
    >
      <svg
        className={clsx(
          '[ transition-all duration-200 ease-in-out ][ rounded-full ][ static ][ active:scale-90 ]',
          {
            '[ !fill-black/50 ]': disabeld,
            '[ bg-gray-30 ][ fill-white ]': !invert && !circle,
            '[ bg-white ][ fill-gray-30 ]': invert && !circle,
            '[ w-8 h-8 ][ ml-5 p-2.5 ]': !circle,
            '[ bg-transparent ][ fill-blue-50 ][ w-5.5 h-5.5 ][ p-1 ]': circle,
          },
          className,
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        data-testid="SolidArrowLeftSVG"
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    </div>
  );
}

export function SolidArrowRight({
  disabeld,
  invert,
  circle,
  containerClassName,
  className,
}: SolidArrowType) {
  return (
    <div
      className={clsx(
        'mobile-hidden',
        '[ rounded-l-4xl ][ flex items-center ][ p-2.5 ][ absolute right-0 top-9/20 ]',
        {
          '[ pointer-events-none ]': disabeld,
          '[ bg-white ]': !invert && !circle,
          '[ bg-gray-30 ]': invert && !circle,
          '[ bg-blue-85/40 ][ rounded-full ][ justify-center ][ w-10 h-10 ]': circle,
        },
        containerClassName,
      )}
      data-testid="SolidArrowRight"
    >
      <svg
        className={clsx(
          '[ transition-all duration-200 ease-in-out ][ rounded-full ][ static ][ active:scale-90 ]',
          {
            '[ !fill-black/50 ]': disabeld,
            '[ bg-gray-30 ][ fill-white ]': !invert && !circle,
            '[ bg-white ][ fill-gray-30 ]': invert && !circle,
            '[ w-8 h-8 ][ mr-5 p-2.5 ]': !circle,
            '[ bg-transparent ][ fill-blue-50 ][ w-5.5 h-5.5 ][ p-1 ]': circle,
          },
          className,
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        data-testid="SolidArrowRightSVG"
      >
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    </div>
  );
}
